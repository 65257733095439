import { Video } from 'components';
import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import { isParagraph } from 'datocms-structured-text-utils';
import { graphql } from 'gatsby';
import { GatsbyImage as Image } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';
import { renderNodeRule, StructuredText } from 'react-datocms';
import Carousel from 'react-elastic-carousel';
import { Ahref, myArrow, Wrapper } from '../components/globalElements';
import Layout from '../components/Layout/Layout';
import HeaderImage from '../images/blue-moon-board.png';
import { GuideBlock, GuideSection, Header } from '../styles/guides.styles';

const Section = ({ id, sectionName, blocks, i }) => {
  const carousel = useRef(null);
  const [activeBlock, setActiveBlock] = useState(blocks[0]);
  const [showTruncated, toggleTruncated] = useState(false);

  const showBlock = (blockId) =>
    setActiveBlock(blocks.find(({ id }) => id === blockId));

  const {
    hasImage,
    hasCarousel,
    hasStaticImage,
    blockContent,
    staticImageField,
    carouselField,
    hasVideo,
    downloadUrl,
    downloadCtaText,
    secondaryUrl,
    secondaryCtaText,
    videoField,
    id: activeId,
  } = activeBlock;

  return (
    <GuideSection key={id} className={i % 2 === 0 ? 'even' : 'odd'}>
      <div className='title'>
        <Wrapper>
          <h2>{sectionName}</h2>
        </Wrapper>
      </div>

      <GuideBlock>
        <ul className='list'>
          {blocks.map(({ blockTitle, id }) => (
            <li key={id}>
              <button
                className={id === activeId ? 'active' : ''}
                onClick={() => showBlock(id)}
              >
                {blockTitle}
              </button>
            </li>
          ))}
        </ul>

        <div className='content'>
          <StructuredText
            data={blockContent}
            customRules={[
              renderNodeRule(isParagraph, ({ node, key }) => {
                const content = toPlainText(node);
                const index = parseInt(key[key.length - 1]);
                if (key.length > 1 && index === 0) {
                  return (
                    <p>
                      {content}
                      <button
                        className='read-more'
                        onClick={() => toggleTruncated(!showTruncated)}
                      >
                        {' '}
                        {!showTruncated ? 'Read More' : 'Show Less'}
                      </button>
                    </p>
                  );
                } else {
                  return showTruncated ? <p>{content}</p> : null;
                }
              }),
            ]}
          />

          <div className='links'>
            {secondaryUrl && (
              <Ahref target='_blank' href={secondaryUrl}>
                {secondaryCtaText ?? 'Secondary CTA Text'}
              </Ahref>
            )}
            <Ahref target='_blank' href={downloadUrl}>
              {downloadCtaText ?? 'Download Guide'}
            </Ahref>
          </div>
        </div>

        <div className='media'>
          {hasStaticImage ? (
            <Image image={staticImageField?.gatsbyImageData} />
          ) : hasCarousel ? (
            <Carousel
              renderArrow={myArrow}
              outerSpacing={0}
              ref={carousel}
              enableSwipe={true}
              enableMouseSwipe={false}
              pagination={false}
              onNextEnd={({ index }) => {
                let resetTimeout;
                clearTimeout(resetTimeout);
                if (index + 1 === carouselField.length) {
                  resetTimeout = setTimeout(() => {
                    carousel.current.goTo(0);
                  }, 1500); // same time
                }
              }}
            >
              {carouselField.map(({ id, gatsbyImageData, customData, alt }) => (
                <>
                  <Image key={id} image={gatsbyImageData} alt={alt} />
                  {customData && <span>{customData.stepContent}</span>}
                </>
              ))}
            </Carousel>
          ) : hasVideo ? (
            <Video src={videoField?.url} type={`video/${videoField?.format}`} />
          ) : null}
        </div>
      </GuideBlock>
    </GuideSection>
  );
};

const Guides = ({ data }) => {
  const sections = data?.allDatoCmsGuideSection?.edges.map(({ node }) => node);
  return (
    <Layout title='Our Guides'>
      <Header>
        <Wrapper>
          <div className='text'>
            <h1>Our Guides</h1>
          </div>
          <div
            className='image'
            style={{
              backgroundImage: `url(${HeaderImage})`,
            }}
          />
        </Wrapper>
      </Header>
      {sections.map(({ sectionName, id, blocks }, i) => (
        <Section key={id} sectionName={sectionName} blocks={blocks} i={i} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  query GetGuideData {
    allDatoCmsGuideSection {
      edges {
        node {
          id
          sectionName
          blocks {
            id
            blockTitle
            hasCarousel
            hasStaticImage
            hasVideo
            blockContent {
              value
            }
            downloadCtaText
            downloadUrl
            secondaryText
            secondaryUrl
            videoField {
              format
              url
            }
            staticImageField {
              gatsbyImageData
              alt
              customData
            }
            carouselField {
              customData
              gatsbyImageData
              alt
            }
          }
        }
      }
    }
  }
`;

export default Guides;
