import styled, { css } from 'styled-components';
import { Wrapper } from '../components/globalElements';
import { responsive } from '../context/theme';

const sliderStyling = css`
  .rec .rec-item-wrapper {
    img {
      height: 50vh;
      width: 100%;
      object-fit: cover;
    }
  }

  ${responsive.md.query(css`
    .rec .rec-item-wrapper {
      img {
        width: 100vw;
      }
    }
  `)}

  .rec .rec-carousel {
    position: relative;
    width: 105%;

    .rec-slider-container {
      margin: 0;
    }

    > button {
      position: absolute;
      bottom: 5%;
      color: white;
      background-color: transparent;
      z-index: 10;
      &:nth-child(1) {
        right: calc(100% - 47px);
      }
      &:last-child {
        left: calc(100% - 47px);
      }
      svg {
        width: 35px;
        height: 35px;
        g {
          circle {
            fill: white;
          }
          path {
            stroke: black;
          }
        }
      }
    }
  }
`;

export const Header = styled.section`
  display: flex;
  position: relative;
  width: 100%;
  height: 250px;
  background-color: var(--primary-color);

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  a {
    display: block;
  }

  .text {
    width: 50%;
    align-items: flex-start;
    h1 {
      color: white;
      font-size: 2.4rem;
    }
  }

  .image {
    width: 50%;
    height: 100%;
    right: 0;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  ${responsive.md.query(css`
    height: 350px;
  `)}
`;

export const GuideSection = styled.section`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .title {
    color: white;
    h2 {
      font-size: 2rem;
      padding: 2.5rem 0;
      text-align: left;
    }
  }

  &.even,
  &.odd {
    .title {
      h2 {
        padding-bottom: 0rem;
      }
    }
  }

  &.even {
    color: black;
    background-color: white;
    .title {
      color: black;
    }

    .list {
      li > button {
        &.active {
          color: black;
        }
      }
    }
  }

  &.odd {
    color: white;
    background-color: var(--dark-blue);

    .title {
      background-color: var(--dark-blue);
    }

    .content {
      .links {
        a:last-child {
          border: solid white;
        }
      }
    }
  }

  ${responsive.md.query(css`
    padding: 5rem 0;
    &.even,
    &.odd {
      .title h2 {
        padding-bottom: 2.5rem;
      }

      .list {
        padding-top: 0;
        padding-bottom: 1.5rem;
      }
    }
  `)}
`;

export const GuideBlock = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .list {
    text-align: left;
    padding-top: 1.5rem;
    position: relative;

    button {
      text-align: left;
      font-size: 1rem;
      color: #b1b1b1;
      font-family: var(--font-regular);

      &.active {
        color: #fff;
        font-family: var(--font-bold);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    p {
      &:not(:last-child) {
        padding-bottom: 1.5rem;
      }
      .read-more {
        font-size: inherit;
        color: var(--primary-color);
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      a:first-child {
        background-color: white;
        color: black;
        border: 2px solid black;
      }
    }
  }

  .media {
    ${sliderStyling}

    section {
      height: 50vh;
    }
  }

  ${responsive.md.query(css`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    > * {
      flex-basis: 100%;
    }
    .list {
      flex-basis: 20%;
    }
  `)}
`;
